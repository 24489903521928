<template>
  <div class="main">
    <div class="contactus-Bak"></div>
    <div class="contactus-content">
      <h2>CONTACT US</h2>
      <h1>联系我们</h1>
      <h5>如果有任何问题请联系我们，我们竭诚为您服务</h5>
      <h3>400-0374-077</h3>
      <h4>门店地址</h4>
    </div>
    <div class="contactus-stores">
      <div class="stores" v-for="item in shopInfo" :key="item.id">
        <img :src="item.storeimg" onclick="return false" alt="" />
        <h3>{{ item.store }}</h3>
        <h4>
          <i class="iconfont" style="color: #019cef">&#xe612;</i
          >{{ item.addres }}
        </h4>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      shopInfo: [
        {
          id: "1",
          store: "笑彤彤夏都店",
          addres: "钧都商城",
          storeimg: "http://text.66666666.ink/bishe/xd.png",
        },
        {
          id: "2",
          store: "笑彤彤博雅苑店",
          addres: "钧州大街亚细亚东门对面",
          storeimg: "http://text.66666666.ink/bishe/byd.png",
        },
        {
          id: "3",
          store: "笑彤彤府东路店",
          addres: "府东路中段森源酒店对面",
          storeimg: "http://text.66666666.ink/bishe/fdd.png",
        },
        {
          id: "4",
          store: "笑彤彤玫瑰园店",
          addres: "西商贸客运站对面(玫瑰园小区)",
          storeimg: "http://text.66666666.ink/bishe/mgy.jpg",
        },
        {
          id: "5",
          store: "笑彤彤步行街店",
          addres: "钧都商场20米路西",
          storeimg: "http://text.66666666.ink/bishe/byd.png",
        },
        {
          id: "6",
          store: "笑彤彤龙屯店",
          addres: "龙屯一矿路口小彬超市",
          storeimg: "http://text.66666666.ink/bishe/ltd.jpg",
        },
        {
          id: "7",
          store: "笑彤彤岗马店",
          addres: "小吕乡政府北50米路东",
          storeimg: "http://text.66666666.ink/bishe/byd.png",
        },
        {
          id: "8",
          store: "神垕店",
          addres: "神垕镇解放路白霜时尚百货负一楼",
          storeimg: "http://text.66666666.ink/bishe/shd.jpg",
        },
      ],
    };
  },
  mounted() {
  //  this.getimg();
  },
  methods: {
    async getimg() {
      const res = await this.$myRequest.post("getimg");
      this.shopInfo = res.data.data;
      console.log(this.shopInfo);
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  width: 100%;
  height: auto;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  .contactus-Bak {
    width: 100%;
    height: 150px;
    box-sizing: border-box;
    background: url(../assets/general/contactus.jpg) bottom center no-repeat;
    background-size: cover;
  }
  .contactus-content {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      font-size: 16px;
      color: #333;
    }
    h2 {
      color: #ccc;
    }
    h5 {
      margin-top: 10px;
      font-size: 8px;
      color: #333;
    }
    h3 {
      margin-top: 10px;
      font-size: 16px;
      color: #333;
    }
    h4 {
      margin-top: 10px;
      font-size: 10px;
      color: orangered;
    }
  }
  .contactus-stores {
    width: 100%;
    margin-top: 5px;
    padding: 10px 10px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    .stores {
      width: 130px;
      margin-left: 10px;
      margin-bottom: 10px;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      transition: all 1s;
      border: 1px solid #eee;
      img {
        width: 100%;
        height: 90px;
      }
      h3 {
        font-size: 7px;
        margin-top: 5px;
        margin-left: 10px;
      }
      h4 {
        font-size: 6px;
        margin-bottom: 5px;
        margin-left: 10px;
        color: #019cef;
      }
    }
    .stores:hover {
      transform: scale(1.05);
      // filter: drop-shadow(0 0 10px#797a7c);
    }
  }
}
@media screen and (max-width: 750px) {
  .main {
    .contactus-Bak {
      width: 100%;
      height: 300px;
      background: url(../assets/general/contactus.jpg) bottom center no-repeat;
      background-size: cover;
    }
    .contactus-content {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      h1 {
        font-size: 46px;
        color: #333;
      }
      h2 {
        font-size: 40px;
        color: #ccc;
      }
      h5 {
        margin-top: 10px;
        font-size: 8px;
        color: #333;
      }
      h3 {
        margin-top: 10px;
        font-size: 44px;
        color: #333;
      }
      h4 {
        margin-top: 10px;
        font-size: 40px;
        color: orangered;
      }
    }
    .contactus-stores {
      width: 100%;
      margin-top: 5px;
      padding: 10px 10px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      .stores {
        width: 70%;
        margin-left: 10px;
        margin-bottom: 40px;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        transition: all 1s;
        border: 1px solid #ccc;
        img {
          width: 100%;
          height: 280px;
        }
        h3 {
          margin-top: 10px;
          margin-left: 20px;
          font-size: 30px;
        }
        h4 {
          margin-left: 20px;
          font-size: 26px;
          color: #019cef;
        }
      }
    }
  }
}
</style>
