import Vue from "vue";
import VueRouter from "vue-router";
import Err from "../views/404.vue";
import Home from "../views/Home.vue";
import Rofile from "../views/Rofile.vue";
import Contactus from "../views/Contactus.vue";
import Bsstory from "../views/Bsstory.vue";
import Babyknow from "../views/Babyknow.vue";
import Babycontent from "../components/general/babacontent";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
      meta: { title: "Ui ART Center 官网" },
    },
    {
      path: "*",
      component: Err,
      //meta: {title: '小木博客'}
      //redirect: '/home'
    },
  ],
  // routes
});

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router;
