<template>
  <div class="home">
    <div class="home-mask animate__animated animate__fadeIn"></div>
    <!-- <el-carousel :height="$store.state.screenWidth ? '170px' : '800px'">
      <el-carousel-item
        class="carousel-item"
        v-for="item in img"
        :key="item.id"
      >
        <img :src="item.url" alt="" style="magin" />
      </el-carousel-item>
    </el-carousel> -->
    <div class="QRcode animate__animated animate__fadeInDownBig animate__delay-0_5s">
      <img src="https://uiart-center.oss-cn-hangzhou.aliyuncs.com/official/qr_code.jpg" alt="">
      <p>扫描二维码</p>
      <p>关注官方小程序</p>
    </div>
    <div class="recommendPage">
      <swiper :options="swiperOption" ref="mySwiper" v-if="showSwiper">
        <!-- <swiper-slide>
          <div class="imageContent">
            <div
              :class="`imageContent-circular animate__animated animate__fadeIn`"
            ></div>
            <div
              :class="`imageContent-logo
                ${
                  !animateFirst
                    ? 'animate__animated animate__fadeInLeft animate__delay-1s'
                    : ''
                }`"
              v-if="swiperIndex == 0"
            >
              <div
                :class="`imageContent-logo-item ${
                  animateFirst ? 'animate__animated animate__fadeInDownBig' : ''
                }`"
              >
                <img :src="logoList[0]" alt="" />
              </div>
              <div
                :class="`imageContent-logo-item ${
                  animateFirst ? 'animate__animated animate__fadeInUpBig' : ''
                }`"
              >
                <img :src="logoList[1]" alt="" />
              </div>
              <div
                :class="`imageContent-logo-item ${
                  animateFirst ? 'animate__animated animate__fadeInDownBig' : ''
                }`"
              >
                <img :src="logoList[2]" alt="" />
              </div>
              <div
                :class="`imageContent-logo-item ${
                  animateFirst ? 'animate__animated animate__fadeInUpBig' : ''
                }`"
              >
                <img :src="logoList[3]" alt="" />
              </div>
              <div
                :class="`imageContent-logo-item ${
                  animateFirst ? 'animate__animated animate__fadeInDownBig' : ''
                }`"
              >
                <img :src="logoList[4]" alt="" />
              </div>
            </div>
          </div>
        </swiper-slide> -->
        <swiper-slide v-for="(item, index) in imageList" :key="index">
          <div class="imageContent1">
            <video v-if="index == 0" :src="item" controls  autoplay muted loop="loop"></video>
            <!-- <div v-if="index >= 0"> -->
              <img
              :src="item" v-if="index > 0"
              alt=""
            />

            <!-- <div class="imageContent1__mobile" v-if="isMobile">
              <img
                src="https://uiart.oss-cn-shanghai.aliyuncs.com/other/2022-03-31/53098da63ba544dd855305dfacba9fc1.jpeg"
                alt=""
              />
            </div> -->
            <!-- </div> -->
          </div>
        </swiper-slide>
        <!-- <div class="swiper-pagination" slot="pagination"></div> -->
        <div class="swiper-button-prev" slot="button-prev" v-if="!isMobile">
          <img
            src="https://uiart-center.oss-cn-hangzhou.aliyuncs.com/official/left%3E.png"
            alt=""
          />
        </div>
        <div class="swiper-button-next" slot="button-next" v-if="!isMobile">
          <img
            src="https://uiart-center.oss-cn-hangzhou.aliyuncs.com/official/right%3E.png"
            alt=""
          />
        </div>
      </swiper>
    </div>

    <div class="home-bottom">
      <div class="home-bottom__text">
        <div class="home-bottom__text__item">媒体中心</div>
        <div class="home-bottom__text__item">如何到达</div>
        <div class="home-bottom__text__item">社会赞助</div>
        <div class="home-bottom__text__item">订阅邮件</div>
        <div class="home-bottom__text__item">营业时间及电话</div>
        <!-- <div class="home-bottom__text__item">外链图标</div> -->
      </div>
    </div>
    <div class="home-right_bottom">
      <div class="home-right_bottom__text">
        <div class="home-right_bottom__text__item">成为会员</div>
        <div class="home-right_bottom__text__item">商务合作</div>
        <div class="home-right_bottom__text__item">加入我们</div>
      </div>
    </div>
	<div class="filings">
		<a href="https://beian.miit.gov.cn">
			苏州创艺无限文化艺术发展有限公司
		</a>
		<a href="https://beian.miit.gov.cn">
			<img class="filings__icon" src="../assets/safe.png" />
			苏ICP备2022013099号
		</a>
	</div>
  </div>
</template>

<script>
import bartop from "@/components/general/bartop.vue";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";

// Import Swiper styles
// import "swiper/css";
export default {
  components: {
    bartop,
    swiper,
    swiperSlide,
  },
  data() {
    let _this = this;
    return {
      isMobile: false,
      flagWidth: this.$store.getters.screenWidth,
      logoList: [
        "https://uiart-center.oss-cn-hangzhou.aliyuncs.com/official/center-logo-animate/1.png?versionId=CAEQNRiBgMCkj_bjgBgiIGQ3MDY1NDRjMWRlNDRlZmRiYjcyMDA0NTY2ZTY2MjFi",
        "https://uiart-center.oss-cn-hangzhou.aliyuncs.com/official/center-logo-animate/2.png?versionId=CAEQNRiBgICij_bjgBgiIDgyOGU1NzRhMTQ4NjQxODlhNGI1Njc4ZTUzOWEzYWRk",
        "https://uiart-center.oss-cn-hangzhou.aliyuncs.com/official/center-logo-animate/3.png?versionId=CAEQNRiBgICsj_bjgBgiIGE0NTY0MTQ2NWY5YjRlNzlhOGM1ZmNmZDExMDQyZjQ0",
        "https://uiart-center.oss-cn-hangzhou.aliyuncs.com/official/center-logo-animate/4.png?versionId=CAEQNRiBgIDCj_bjgBgiIDE5M2Q3YTYzN2EyMjRlMzhhYjg1NzY3NTc1NjdhZDFj",
      ],
      imageList: [
        'https://uiart-center.oss-cn-hangzhou.aliyuncs.com/official/home-video.MP4',
        // 'https://uiart-center.oss-cn-hangzhou.aliyuncs.com/official/swiper1.jpg?v=1.1',
        require('@/assets/general/fangyi.jpg'),
        'https://uiart-center.oss-cn-hangzhou.aliyuncs.com/official/swiper2.jpg?v=1',
        'https://uiart-center.oss-cn-hangzhou.aliyuncs.com/official/swiper3.jpg?v=1',
        'https://uiart-center.oss-cn-hangzhou.aliyuncs.com/official/swiper4.jpg?v=1',
        'https://uiart-center.oss-cn-hangzhou.aliyuncs.com/official/swiper5.jpg?v=1',
		'https://uiart-center.oss-cn-hangzhou.aliyuncs.com/official/swiper11.jpg?v=1',
        'https://uiart-center.oss-cn-hangzhou.aliyuncs.com/official/swiper6.jpg?v=1',
        'https://uiart-center.oss-cn-hangzhou.aliyuncs.com/official/swiper7.jpg?v=1',
        'https://uiart-center.oss-cn-hangzhou.aliyuncs.com/official/swiper8.jpg?v=1',
        'https://uiart-center.oss-cn-hangzhou.aliyuncs.com/official/swiper9.jpg?v=1',
        'https://uiart-center.oss-cn-hangzhou.aliyuncs.com/official/swiper10.jpg?v=1',
        'https://uiart-center.oss-cn-hangzhou.aliyuncs.com/official/swiper11.jpg?v=1',
        
      ],
      swiperIndex: 0,
      animateFirst: true,
      showSwiper: true,
      swiperOption: {
        loop: false,
        autoplay: "none",
        speed: 2000,
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },

        on: {
          transitionStart: function () {
            console.log(this.realIndex);
            _this.swiperIndex = this.realIndex;
            if (_this.swiperIndex === 1) {
              _this.animateFirst = false;
            }

            console.log(_this.swiperIndex);
            // alert(this.realIndex); //切换结束时，告诉我现在是第几个slide
          },
          // slidePrevTransitionStart: function () {
          //   _this.swiperIndex = this.realIndex
          //   if(_this.swiperIndex === 1){
          //     _this.animateFirst = false
          //   }
          //   // console.log(_this.animateFirst)
          //   // if(_this.swiperIndex === 1){
          //   //   _this.animateFirst = false
          //   // }
          //   // _this.swiperIndex = this.realIndex
          //   // console.log(_this.swiperIndex); //切换结束时，告诉我现在是第几个slide
          // },
        },
      },
      img: [
        // 在url后边加上require（"这里是图片路径"）
        { id: "1", url: "http://text.66666666.ink/scroller/banner.jpg" },
        { id: "2", url: "http://text.66666666.ink/scroller/homebak2.png" },
        // { id: "2", url: require("@/assets/general/homebak2.png") },
      ],
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  mounted() {
    if (document.body.clientWidth <= 1000) {
      this.$store.commit("change", true);
    } else {
      this.$store.commit("change", false);
    }
    console.log("this is current swiper instance object", this.swiper);
    if (
      navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    ) {
      //手机
      this.isMobile = true;
      this.swiperOption.effect = "slide";
      this.swiperOption.speed = 1000;
      this.showSwiper = false;
      setTimeout(() => {
        this.showSwiper = true;
      }, 0);
    }
    //this.getscroller();
  },
  methods: {
    async getscroller() {
      const res = await this.$myRequest.post("getscroller");
      this.img = res.data.data;
    },
    onSwiper(swiper) {
      console.log(swiper);
    },
    onSlideChange() {
      console.log("slide change");
    },
  },
};
</script>
<style lang="scss" scoped>
// @import '@/assets/style/animate';
img {
  width: 100%;
}

.home{
  position: relative;
  .QRcode{
    position: absolute;
    right: 100px;
    z-index: 999;
    p{
      font-size: 16px;
      text-align: center;
      font-family: PingFangSC-Regular, PingFang SC;
      &:nth-child(1){
        margin-top: 20rpx;
      }
    }
    img{
      width: 150px;
      height: 150px;
      display: block;
      margin-bottom: 15px;
    }
  }
}

.home-mask {
  width: 100vw;
  height: 100vh;
  // background: url(https://uiart-center.oss-cn-hangzhou.aliyuncs.com/official/bg-mask.png);
  background: rgba($color: #ffffff, $alpha: 0.9);
  background-size: 100% 100%;
  position: fixed;
  top: 0;
  left: 0;
}
.content {
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  .content-titl {
    width: 94%;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h3 {
      font-size: 12px;
    }
    span {
      margin-top: 10px;
      text-align: center;
      font-size: 8px;
      color: #9a9a9a;
    }
  }
  .content-show {
    width: 50%;
    //height: ;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .goodsbox {
      width: 49%;
      height: 180px;
      margin-bottom: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 100%;
        height: 80%;
      }
      p {
        margin-top: 10px;
        font-size: 10px;
      }
      span {
        margin-top: 5px;
        font-size: 8px;
        color: #9a9a9a;
      }
    }
  }
}

.recommendPage {
  height: calc(100vh - 115px);
}
.recommendPage .swiper-container {
  position: relative;
  width: 100%;
  height: 100%;
  // margin-top: -40px;
  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1200px;
    overflow: hidden;
  }
  .imageContent {
    position: relative;
    width: 1064px;
    height: 645px;
    display: flex;
    align-content: center;
    justify-content: center;

    .imageContentText {
      width: 481px;
      height: 349px;
      background: rgba(255, 255, 255, 0.82);
      backdrop-filter: blur(8px);
      position: absolute;
      left: 0px;
      top: 211px;
      padding: 56px;
      box-sizing: border-box;
      text-align: left;
      .imageContentText-topline {
        width: 29px;
        height: 10px;
        background: #000000;
      }
      .imageContentText-title {
        font-size: 43px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 60px;
        text-align: left;
        margin-top: 13px;
      }
      .imageContentText-content {
        font-size: 21px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 29px;
      }
      .imageContentText-boottomline {
        position: absolute;
        bottom: 61px;
        left: 56px;
        width: 5px;
        height: 10px;
        background: #000000;
      }
    }
    .imageContent-circular {
      width: 627px;
      height: 627px;
      background: #ffffff;
      opacity: 0.85;
      border: 1px solid #8a8a8a;
      border-radius: 50%;
      margin-right: 0;
      position: absolute;
    }
    .imageContent-logo {
      // position: absolute;
      // z-index: 1;
      display: flex;
      margin-left: -100px;
      margin-top: -100px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 588px;
    }
    .imageContent-logo-item {
      height: 329px;
      margin-left: -2px;
      img {
        display: block;
        height: 100%;
        width: auto;
      }
    }
  }

  .imageContent1 {
    position: relative;
    width: 1064px;
    height: 645px;
    display: flex;
    align-content: center;
    justify-content: center;
    video{
      width: 100%;
      height: 100%;
    }

    img{
      max-height: 645px;
    }
    .imageContentText {
      width: 481px;
      height: 349px;
      background: rgba(255, 255, 255, 0.82);
      backdrop-filter: blur(8px);
      position: absolute;
      left: 0px;
      top: 211px;
      padding: 56px;
      box-sizing: border-box;
      text-align: left;
      .imageContentText-topline {
        width: 29px;
        height: 10px;
        background: #000000;
      }
      .imageContentText-title {
        font-size: 32px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 60px;
        text-align: left;
        margin-top: 13px;
      }
      .imageContentText-content {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 29px;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        word-break: break-all;
      }
      .imageContentText-boottomline {
        position: absolute;
        bottom: 61px;
        left: 56px;
        width: 5px;
        height: 10px;
        background: #000000;
      }
    }
    .imageContent-circular {
      width: 627px;
      height: 627px;
      background: #ffffff;
      opacity: 0.85;
      border: 1px solid #8a8a8a;
      border-radius: 50%;
      margin-right: 0;
      position: absolute;
    }
    .imageContent-logo {
      // position: absolute;
      // z-index: 1;
      display: flex;
      margin-left: -100px;
      margin-top: -100px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 588px;
    }
    .imageContent-logo-item {
      height: 329px;
      margin-left: -2px;
      img {
        display: block;
        height: 100%;
        width: auto;
      }
    }
  }
}
.recommendPage .swiper-container .swiper-slide {
  width: 100%;
  line-height: 200px;
  // background: rgba($color: #ffffff, $alpha: 0.7);
  color: #000;
  font-size: 16px;
  text-align: center;
}
.recommendPage .swiper-container .swiper-button-prev {
  left: 120px;
  background: none;
  width: 85px;
  height: 85px;
  // background: #ffffff;
}
.swiper-button-next {
  right: 120px;
  background: none;
  width: 85px;
  height: 85px;
  // background: #ffffff;
  // border: 1px solid #000000;
}
.home-bottom {
  position: absolute;
  bottom: 30px;
  left: 91px;
  &__text {
    display: flex;
    &__item {
	  height: 50px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 50px;
      opacity: 0.67;
      margin-right: 86px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.home-right_bottom {
  position: absolute;
  bottom: 30px;
  right: 79px;
  &__text {
    display: flex;
    // flex-direction: column;
    &__item {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      // line-height: 22px;
      // margin-bottom: 34px;
      height: 50px;
      display: flex;
      align-items: center;
	  margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.filings{
	position: absolute;
	transform: translateX(-50%);
	left: 50%;
	bottom: 20px;
	display: flex;
	align-items: center;
	z-index: 999;
	img{
		height: 20px;
		width: 20px;
		// margin-right: 5px;
	}
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #000000;
	a{
		display: flex;
		align-items: center;
		&:nth-child(1){
			margin-right: 10px;
		}
	}
}

.animate__animated {
  animation-duration: 1.5s; //动画持续时间
}

.animate__delay-0_5s {
  animation-delay: 0.5s;
}

.animate__delay-1_5s {
  animation-delay: 1.1s;
}

.animate__delay-1_7s {
  animation-delay: 1.2s;
}

.animate__delay-1_9s {
  animation-delay: 1.3s;
}

.animate__delay-2_1s {
  animation-delay: 1.4s;
}
// .animate__delay-1_7s{
//   animation-duration: 1.7s;
// }

@media screen and (max-width: 750px) {
  .animate__animated {
    animation-duration: 1s; //动画持续时间
  }

  .animate__delay-1_5s {
    animation-delay: 0.5s;
  }

  .animate__delay-1_7s {
    animation-delay: 0.6s;
  }

  .animate__delay-1_9s {
    animation-delay: 0.7s;
  }

  .animate__delay-2_1s {
    animation-delay: 0.8s;
  }

  img {
    width: 100%;
    // height: 400px;
  }

  .content {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    .content-titl {
      width: 94%;
      height: 300px;
      //margin-top: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h3 {
        font-size: 40px;
      }
      span {
        margin-top: 10px;
        text-align: center;
        font-size: 32px;
        color: #9a9a9a;
      }
    }
    .content-show {
      width: 94%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .goodsbox {
        width: 49%;
        height: 350px;
        margin-bottom: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 100%;
          height: 70%;
        }
        p {
          margin-top: 10px;
          font-size: 32px;
        }
        span {
          margin-top: 10px;
          font-size: 28px;
          color: #9a9a9a;
        }
      }
    }
  }

  .recommendPage .swiper-container {
    position: relative;
    width: 100%;
    height: calc(100% - 600px);
    // margin-top: -40px;
    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100% !important;
      overflow: hidden;
    }
    .imageContent {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      align-content: center;
      justify-content: center;

      img {
        width: 100%;
        height: 1500px;
        object-fit: cover;
      }

      .imageContentText {
        width: 481px;
        height: 349px;
        background: rgba(255, 255, 255, 0.82);
        backdrop-filter: blur(8px);
        position: absolute;
        left: 0px;
        top: 211px;
        padding: 56px;
        box-sizing: border-box;
        text-align: left;
        .imageContentText-topline {
          width: 29px;
          height: 10px;
          background: #000000;
        }
        .imageContentText-title {
          font-size: 43px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          line-height: 60px;
          text-align: left;
          margin-top: 13px;
        }
        .imageContentText-content {
          font-size: 21px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          line-height: 29px;
        }
        .imageContentText-boottomline {
          position: absolute;
          bottom: 61px;
          left: 56px;
          width: 5px;
          height: 10px;
          background: #000000;
        }
      }
      .imageContent-circular {
        width: 1600px;
        height: 1600px;
        background: #ffffff;
        opacity: 0.85;
        border: 1px solid #8a8a8a;
        border-radius: 50%;
        margin-right: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
      }
      .imageContent-logo {
        // position: absolute;
        // z-index: 1;
        // display: flex;
        margin-left: 0;
        // margin-top: -100px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
      .imageContent-logo-item {
        height: 500px;
        margin-left: -2px;
        img {
          display: block;
          height: 100%;
          width: auto;
        }
      }
    }

    .imageContent1 {
      position: absolute;
      width: calc(100% - 200px);
      height: 1500px;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        max-height: 100%;
      }

      &__mobile {
        position: absolute;
        width: calc(100% - 200px);
        height: 1500px;
        img {
          width: 763 * 2px;
          height: 960 * 2px;
          
          object-fit: cover;
        }

        .imageContentText {
          width: 962px;
          height: 600px;
          background: rgba(255, 255, 255, 0.82);
          backdrop-filter: blur(8px);
          position: absolute;
          left: 0px;
          top: auto;
          bottom: 200px;
          padding: 56px;
          box-sizing: border-box;
          text-align: left;
          .imageContentText-topline {
            width: 58px;
            height: 20px;
            background: #000000;
          }
          .imageContentText-title {
            font-size: 70px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
            line-height: 160px;
            text-align: left;
            margin-top: 26px;
          }
          .imageContentText-content {
            font-size: 50px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;
            line-height: 75px;
          }
          .imageContentText-boottomline {
            position: absolute;
            bottom: 61px;
            left: 56px;
            width: 10px;
            height: 20px;
            background: #000000;
          }
        }
      }

      .imageContent-circular {
        width: 1600px;
        height: 1600px;
        background: #ffffff;
        opacity: 0.85;
        border: 1px solid #8a8a8a;
        border-radius: 50%;
        margin-right: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
      }
      .imageContent-logo {
        // position: absolute;
        // z-index: 1;
        // display: flex;
        margin-left: 0;
        // margin-top: -100px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
      .imageContent-logo-item {
        height: 500px;
        img {
          display: block;
          height: 100%;
          width: auto;
        }
      }
    }
  }

  .home-bottom {
    position: absolute;
    bottom: 45px;
    left: 91px;
    &__text {
      display: flex;
      flex-wrap: wrap;
      &__item {
        font-size: 56px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        margin-bottom: 60px;
        // line-height: 20px;
        opacity: 0.67;
        margin-right: 86px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .home-right_bottom {
    position: absolute;
    bottom: 260px;
    left: 91px;
    &__text {
      display: flex;
      flex-wrap: wrap;
      &__item {
        font-size: 56px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 22px;
        margin-bottom: 34px;
        margin-right: 86px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .home{
    position: relative;
    .QRcode{
      position: absolute;
      right: 150px;
      bottom: 300px;
      z-index: 999;
      p{
        font-size: 16px;
        text-align: center;
        font-family: PingFangSC-Regular, PingFang SC;
        &:nth-child(1){
          margin-top: 20rpx;
        }
      }
      img{
        width: 450px;
        height: 450px;
        display: block;
        margin-bottom: 15px;
      }
    }
  }
}

.animate__fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@keyframes fadeInLeft {
  0% {
    -webkit-transform: translate3d(-200px, 0, 0);
    transform: translate3d(-200px, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@keyframes fadeInRight {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(-103px, 0, 0);
    transform: translate3d(-103px, 0, 0);
  }
}

.animate__fadeInRight1 {
  -webkit-animation-name: fadeInRight1;
  animation-name: fadeInRight1;
}

@keyframes fadeInRight1 {
  0% {
    -webkit-transform: translate3d(103px, 0, 0);
    transform: translate3d(103px, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInRight__mobile {
  -webkit-animation-name: fadeInRightMobile;
  animation-name: fadeInRightMobile;
}

@keyframes fadeInRightMobile {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(-100px, 0, 0);
    transform: translate3d(-100px, 0, 0);
  }
}

.animate__fadeInRight__mobile1 {
  -webkit-animation-name: fadeInRightMobile1;
  animation-name: fadeInRightMobile1;
}

@keyframes fadeInRightMobile1 {
  0% {
    -webkit-transform: translate3d(103px, 0, 0);
    transform: translate3d(103px, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
</style>
