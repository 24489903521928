<template>
  <div class="nav">
    <div class="main">
      <span class="title">{{ info.title }}</span>
      <span class="time">{{ info.time }}</span>
      <div class="main-content">
        <div class="markdown-body" v-html="info.demo"></div>
      </div>
    </div>
  </div>
</template>
<script>
import "mavon-editor/dist/css/index.css";
export default {
  data() {
    return {
      info: "",
      id: "",
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getarticle();
  },
  methods: {
    async getarticle() {
      // let data = {
      //   id: this.id
      // };
      // const res = await this.$myRequest.post("getarticle", data);
      (this.info = {
        id: "5",
        minimg: "http://test.66666666.ink/scroller/joe1617160638577.jpg",
        title: "宝宝怎样学走路",
        demo: "<p>学习走路是宝宝成长过程中一件非常重要的事情，学好了走路对孩子以后的生活有着非常大的帮助。接下来就跟各位爸爸妈妈说4个走路的小技巧。</p>\r\n<p>1.避免通过学步带锻炼</p>\r\n<p>虽然用学步带能够让宝宝自己保持平衡，也能够更好地照顾宝宝，确保安全。但是，学步带对于宝宝学习走路会起到妨碍作用，宝宝通过学步带无法很好地锻炼小腿肌肉，对臀部和骨盆肌肉的正常发展也产生了一定的影响。</p>",
        time: "3/31/2021 11:18:45",
      }),
        (this.info.time = this.$formData(this.info.time));
    },
  },
};
</script>

<style lang="scss" scoped>
.nav {
  width: 100%;
  height: auto;
  background: #f0f0f0;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  .main {
    width: 60%;
    margin: 0 auto;
    padding: 10px 10px;
    box-sizing: border-box;
    border-radius: 1px;
    background: #fff;
    display: flex;
    flex-direction: column;
    .title {
      font-size: 12px;
      color: #555555;
    }
    .time {
      margin-top: 3px;
      font-size: 8px;
      color: #7d7d7d;
    }
    .main-content {
      width: 100%;
      margin-top: 10px;
      padding: 10px 10px;
      box-sizing: border-box;
      border-top: 0.5px solid #ccc;
      .markdown-body {
        /deep/ p {
          line-height: 150%;
          font-size: 8px;
          color: #555555;
          text-indent: 2em;
        }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .nav {
    width: 100%;
    height: auto;
    background: #ffffff;
    padding: 30px 0;
    display: flex;
    justify-content: center;
    .main {
      width: 90%;
      margin: 0 auto;
      padding: 20px 20px;
      box-sizing: border-box;
      border-radius: 10px;
      background: #fff;
      display: flex;
      flex-direction: column;
      .title {
        font-size: 36px;
        font-weight: bold;
        color: #555555;
      }
      .time {
        margin-top: 3px;
        font-size: 24px;
        color: #7d7d7d;
      }
      .main-content {
        width: 100%;
        margin-top: 10px;
        padding: 10px 10px;
        box-sizing: border-box;
        border-top: 0.5px solid #ccc;
        .markdown-body {
          /deep/ p {
            line-height: 150%;
            font-size: 28px;
            color: #555555;
            text-indent: 2em;
            display: flex;
          }
          /deep/ img {
            width: 90%;
            height: 60%;
            margin: 20px auto;
          }
        }
      }
    }
  }
}
</style>
