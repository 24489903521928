<template>
  <div class="main">
    <div class="profilebak"></div>
    <!-- 企业简介 -->
    <div class="rofile-main">
      <div class="rofile-main-list">
        <h5 style=" font-size: 36px;color:#333">公司简介</h5>
        <h6 style=" font-size: 14px;color:#666">COMPANY PROFILE</h6>
      </div>
      <div class="rofile-main-list">
        <div>
          <h2>2010</h2>
          <h6>成立于2010年</h6>
        </div>
        <div>
          <h2>11</h2>
          <h6>专注母婴11年</h6>
        </div>
        <div>
          <h2>8</h2>
          <h6>拥有门店8家</h6>
        </div>
      </div>
      <div class="rofile-main-list">
        <p>{{ rofile1 }}</p>
      </div>
    </div>
    <!-- 企业文化 -->
    <div class="culture">
      <div class="rofile-main-list">
        <div class="h5">公司文化</div>
        <div class="h6">
          CORPORATE CULTURE
        </div>
      </div>
      <div class="culture-column">
        <div class="culture-column-list">
          <img src="@/assets/icon/0.png" alt="" />
          <h5>企业愿景</h5>
          <span style="text-align:center">
            为追求员工的幸福 <br />
            而努力奋斗</span
          >
          <span></span>
        </div>
        <div class="culture-column-list">
          <img src="@/assets/icon/1.png" alt="" />
          <h5>企业使命</h5>
          <span>顾客满意</span>
          <span>员工满意</span>
          <span>合作伙伴满意</span>
        </div>
        <div class="culture-column-list">
          <img src="@/assets/icon/2.png" alt="" />
          <h5>企业价值观</h5>
          <span>诚实守信</span>
          <span>勤奋好学</span>
          <span>有爱心</span>
          <span>不走捷径</span>
        </div>
        <div class="culture-column-list">
          <img src="@/assets/icon/3.png" alt="" />
          <h5>文化信仰</h5>
          <span>公平 快乐</span>
          <span>自由 博爱</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import bartop from "@/components/general/bartop.vue";
export default {
  components: {
    bartop
  },
  data() {
    return {
      rofile1: `禹州市笑彤彤儿童百货商行于2010年创立,主要经营婴童服装、鞋帽、化妆品、百货、
        预包装食品兼散装食品;乳制品（含婴幼儿配方乳粉)、消毒产品、保健食品批发兼零、孕，
        婴幼儿洗浴服务。目前在禹州先后设立了数十家分公司，雇员超500人。公司专注于母婴
        行业，打造行业精英，"轻松育儿 品质生活"。`
    };
  }
};
</script>
<style lang="scss" scoped>
.main {
  display: flex;
  flex-direction: column;
}
.profilebak {
  width: 100%;
  height:150px;
  background: red;
  background: url('../assets/general/profilebak.png') center  no-repeat;
  background-size:cover;
}
.rofile-main {
  height: 180px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  .rofile-main-list:nth-child(1) {
    margin-top: 15px;
  }
  .rofile-main-list:nth-child(2) {
    width: 60%;
    //height: px;
    div {
      width: 33%;
      height: 60px;
      float: left;
      border-right: 1px dotted #ccc;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h2 {
        font-size: 18px;
        color: #0068b6;
      }
      h6 {
        font-size: 14px;
        color: #333;
      }
    }
    div:nth-child(3) {
      border: none;
    }
  }
  .rofile-main-list:nth-child(3) {
    width: 60%;
    height: 50px;
    p {
      line-height: 150%;
      font-size: 8px;
      text-indent: 2em;
    }
  }
}
 .culture {
  width: 100%;
  height: 180px;
  box-sizing: border-box;
  background: url(../assets/general/culturebak.jpg) center no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .rofile-main-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .h5 {
      font-size: 18px;
      color: #fff;
    }
    .h6 {
      font-size: 12px;
      color: #fff;
    }
  }
  .culture-column {
    width: 70%;
    height: 50%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    div {
      display: flex;
      height: 70px;
      flex-direction: column;
      align-items: center;
      vertical-align: top;
      color: #fff;
      h5 {
        font-size: 8px;
      }
      span{
        font-size: 6px;
      }
    }
  }
  }
@media screen and (max-width: 750px) {
  .profilebak {
    width: 100%;
    height: 300px;
    background: url('../assets/general/phonebak.png') center  no-repeat;
    background-size: cover;
  }
  .rofile-main {
    height: 700px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    .rofile-main-list {
      margin-top: 5px;
    }
    .rofile-main-list:nth-child(1) {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .rofile-main-list:nth-child(2) {
      width: 80%;
      margin-top: 30px;
      div {
        width: 33%;
        height: 60px;
        float: left;
        border-right: 1px dotted #ccc;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h2 {
          font-size: 40px;
          color: #0068b6;
        }
        h6 {
          font-size: 14px;
          color: #333;
        }
      }
      div:nth-child(3) {
        border: none;
      }
    }
    .rofile-main-list:nth-child(3) {
      width: 80%;
      //height: 50px;
      margin-top: 40px;
      p {
        font-size: 30px;
        line-height: 150%;
        text-indent: 2em;
      }
    }
  }
  .culture {
    width: 100%;
    height: 450px;
    margin-top: 15px;
    box-sizing: border-box;
    background: url(../assets/general/culturebak.jpg) center no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .rofile-main-list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .h5 {
        font-size: 40px;
        color: #fff;
      }
      .h6 {
        font-size: 20px;
        color: #fff;
      }

    }
    .culture-column {
      width: 80%;
      height: 50%;
      display: flex;
      justify-content: space-around;
      align-items: flex-start;
      div {
        margin-top: 20px;
        display: flex;
        height: 70px;
        flex-direction: column;
        align-items: center;
        vertical-align: top;
        color: #fff;
        img{
          width: 70px;
        }
        h5 {
          font-size: 8px;
        }
        span{
          margin-top: 5px;
          font-size: 20px
        }
      }
    }
  }
}

</style>
