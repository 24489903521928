import axios from "axios";
const http = axios.create({
  baseURL: "http://localhost:3000/xttetbh/",
  // baseURL: "https://exgt.xyz:3000/xttetbh/",
  headers: {
    //"Content-Type": " application/x-www-form-urlencoded",
    // token: Cookies.get("token"),
  },
});

export { http };
