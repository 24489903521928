<template>
  <div class="main">
    <div class="contactus-Bak"></div>
    <div class="contactus-column">
      <div class="column" v-for="item in babyInfo" :key="item.id">
        <div class="column-img">
          <img :src="item.minimg" onclick="return false" alt="" />
        </div>
        <div class="column-title">
          <h1>{{ item.title }}</h1>
          <h2>{{ item.title }}</h2>
          <div class="column-title-time">
            <h3 class="web-font">{{ item.time.substring(5, 10) }}</h3>
            <h4>/{{ item.time.substring(0, 4) }}</h4>
          </div>
        </div>
        <div class="column-btn">
          <div class="btn" @click="btn" :data-index="item.id">More</div>
        </div>
        <!-- <div class="column-btn" v-if="!$store.state.screenWidth">
          <div class="btn" @click="btn">More</div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      babyInfo: [
        {
          id: "5",
          minimg: "http://text.66666666.ink/scroller/joe1617160638577.jpg",
          title: "宝宝怎样学走路",
          demo: "<p>学习走路是宝宝成长过程中一件非常重要的事情，学好了走路对孩子以后的生活有着非常大的帮助。接下来就跟各位爸爸妈妈说4个走路的小技巧。</p>\r\n<p>1.避免通过学步带锻炼</p>\r\n<p>虽然用学步带能够让宝宝自己保持平衡，也能够更好地照顾宝宝，确保安全。但是，学步带对于宝宝学习走路会起到妨碍作用，宝宝通过学步带无法很好地锻炼小腿肌肉，对臀部和骨盆肌肉的正常发展也产生了一定的影响。</p>",
          time: "3/31/2021 11:18:45",
        },
      ],
    };
  },
  mounted() {
    // this.getbaby();
  },
  methods: {
    async getbaby() {
      const res = await this.$myRequest.post("getbabyknow");
      this.babyInfo = res.data.data;
    },
    btn(e) {
      this.$router.push({
        path: "/babycontent",
        query: {
          id: e.target.dataset.index,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@font-face {
  font-family: "webfont";
  font-display: swap;
  src: url("//at.alicdn.com/t/webfont_wq8hklsmov9.eot"); /* IE9*/
  src: url("//at.alicdn.com/t/webfont_wq8hklsmov9.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("//at.alicdn.com/t/webfont_wq8hklsmov9.woff2")
      format("woff2"),
    url("//at.alicdn.com/t/webfont_wq8hklsmov9.woff") format("woff"),
    /* chrome、firefox */ url("//at.alicdn.com/t/webfont_wq8hklsmov9.ttf")
      format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url("//at.alicdn.com/t/webfont_wq8hklsmov9.svg#思源黑体-极细")
      format("svg"); /* iOS 4.1- */
}
.web-font {
  font-family: "webfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main {
  width: 100%;
  height: auto;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  .contactus-Bak {
    width: 70%;
    height: 150px;
    box-sizing: border-box;
    background: url(http://text.66666666.ink/bishe/babyknow.png) center center
      no-repeat;
    background-size: 80%;
  }
  .contactus-column {
    width: 100%;
    border-top: 0.5px solid #eee;
    display: flex;
    flex-direction: column;
    align-items: center;
    .column {
      width: 60%;
      height: 100px;
      padding: 10px 5px;
      box-sizing: border-box;
      border-bottom: 0.5px solid #eee;
      display: flex;
      justify-content: space-between;
      .column-img {
        width: 30%;
        height: 100%;
        img {
          width: 85%;
          height: 100%;
          border-radius: 4px;
        }
      }
      .column-title {
        width: 45%;
        height: 100%;
        padding: 10px 0;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h1 {
          font-size: 10px;
          color: #444444;
        }
        h2 {
          font-size: 8px;
          color: #7d7d7d;
        }
        .column-title-time {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          h3 {
            font-size: 12px;
            font-weight: 100;
            color: #a3afb7;
            margin-right: 5px;
          }
          h4 {
            font-size: 8px;
            font-weight: 100;
            color: #a3afb7;
          }
        }
      }
      .column-btn {
        width: 25%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .btn {
          width: 50%;
          height: 15px;
          font-size: 6px;
          text-align: center;
          line-height: 15px;
          color: #43c9c3;
          border: 0.5px solid #43c9c3;
          transition: all 0.5s;
        }
        .btn:hover {
          cursor: pointer;
          color: #fff;
          background: #43c9c3;
        }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .main {
    .contactus-Bak {
      width: 100%;
      height: 300px;
      //argin-top: 20px;
      box-sizing: border-box;
      background: url(http://text.66666666.ink/bishe/babyknow.png) center
        no-repeat;
      background-size: 100%;
    }
    .contactus-column {
      width: 100%;
      border-top: 0.5px solid #eee;
      display: flex;
      flex-direction: column;
      align-items: center;
      .column {
        width: 90%;
        height: 160px;
        padding: 10px 5px;
        box-sizing: border-box;
        border-bottom: 0.5px solid #eee;
        display: flex;
        justify-content: space-around;
        .column-img {
          width: 40%;
          height: 100%;
          img {
            width: 90%;
            height: 100%;
            border-radius: 4px;
          }
        }
        .column-title {
          width: 60%;
          height: 100%;
          padding: 10px 0;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          h1 {
            font-size: 28px;
            color: #444444;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          h2 {
            font-size: 22px;
            color: #7d7d7d;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .column-title-time {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            h3 {
              font-size: 20px;
              font-weight: 100;
              color: #a3afb7;
              margin-right: 5px;
            }
            h4 {
              font-size: 18px;
              font-weight: 100;
              color: #a3afb7;
            }
          }
        }
        .column-btn {
          width: 25%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .btn {
            width: 80%;
            height: 40px;
            font-size: 6px;
            text-align: center;
            line-height: 15px;
            color: #43c9c3;
            border: 0.5px solid #43c9c3;
            transition: all 0.5s;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .btn:hover {
            cursor: pointer;
            color: #fff;
            background: #43c9c3;
          }
        }
      }
    }
  }
}
</style>
