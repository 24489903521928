import Vue from "vue";
import App from "./App.vue";
// import './rem';
import router from "./router";
import "./plugins/element.js";
import store from "./store";
import { http } from "../src/utils/myRequest";
import "amfe-flexible";
import mavonEditor from "mavon-editor";
import 'vue2-toast/lib/toast.css';
import Toast from 'vue2-toast';
Vue.use(Toast);
import animated from 'animate.css'
Vue.use(animated)



import "mavon-editor/dist/css/index.css";
import formdata from"../src/utils/time"
//Vue.use(mavonEditor);
Vue.prototype.$myRequest = http;
Vue.prototype.$formData=formdata
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
