<template>
  <div class="nav">
    <div class="main" v-if="!isMobile">
      <img src="@/assets/general/uiart-logo.png" alt="" />
      <div class="column">
        <div
          class="link"
          v-for="(item, index) in navList"
          :class="activeClass === index ? 'active' : ''"
          :key="index"
          @click="checkNav(index)"
        >
          {{ item.label }}
        </div>
        <!-- <div class="link">
          <router-link class="routerlink" to="/rofile">展览</router-link>
        </div>
        <div class="link">
          <router-link class="routerlink" to="/babyknow">活动</router-link>
        </div>
        <div class="link">
          <router-link class="routerlink" to="/bsstory">特色</router-link>
        </div>
        <div class="link">
          <router-link class="routerlink" to="/contactus">展厅</router-link>
        </div>
        <div class="link">
          <router-link class="routerlink" to="/contactus">商店</router-link>
        </div>
        <div class="link">
          <router-link class="routerlink" to="/contactus">关于</router-link>
        </div> -->
      </div>
      <div class="search">
        <div class="search-icon">
          <img src="@/assets/general/search.png" alt="" />
        </div>
        <div class="search-input"><input type="text" /></div>
        <div class="search-button">搜索</div>
      </div>
    </div>
    <div class="main-mobile" v-if="isMobile">
      <div class="main-mobile-logo">
        <img src="@/assets/general/uiart-logo.png" alt="" />
      </div>
      <div class="main-mobile-nav" @click="showMenu">
        <img src="@/assets/general/nav.png" alt="" />
      </div>
    </div>
    <div class="main-mobile-column" v-if="showNav">
      <div class="main-mobile-column__search">
        <input type="text" placeholder="请输入内容">
        <div class="main-mobile-column__search-icon">
          <img src="@/assets/general/search.png" alt="" />
        </div>
      </div>
      <div
        class="link"
        v-for="(item, index) in navList"
        :class="activeClass === index ? 'active' : ''"
        :key="index"
        @click="checkNav(index)"
      >
        {{ item.label }}
      </div>
      <!-- <div class="link">
          <router-link class="routerlink" to="/rofile">展览</router-link>
        </div>
        <div class="link">
          <router-link class="routerlink" to="/babyknow">活动</router-link>
        </div>
        <div class="link">
          <router-link class="routerlink" to="/bsstory">特色</router-link>
        </div>
        <div class="link">
          <router-link class="routerlink" to="/contactus">展厅</router-link>
        </div>
        <div class="link">
          <router-link class="routerlink" to="/contactus">商店</router-link>
        </div>
        <div class="link">
          <router-link class="routerlink" to="/contactus">关于</router-link>
        </div> -->
    </div>
    <!-- <el-alert title="敬请期待" type="warning" center v-show="showMessage"> </el-alert> -->
  </div>
</template>
<script>

export default {
  data() {
    return {
      navList: [
        {
          link: "/",
          label: "首页",
        },
        {
          link: "/rofile",
          label: "展览",
        },
        {
          link: "/babyknow",
          label: "活动",
        },
        {
          link: "/bsstory",
          label: "特色",
        },
        {
          link: "/contactus",
          label: "餐厅",
        },
        {
          link: "/contactus",
          label: "商店",
        },
        {
          link: "/contactus",
          label: "关于",
        },
      ],
      activeClass: 0,
      showMessage: false,
      isMobile: false,
      showNav: false,
      menuShow: false,
    };
  },
  mounted() {
    // console.log(document.body.clientWidth)
    if (
      navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    ) {
      //手机
      this.isMobile = true;
    }
  },
  methods: {
    checkNav(index) {
      if (index > 0) {
        // this.$message({
        //   message: "敬请期待",
        //   type: "warning",
        // });
        this.$toast.center('敬请期待');
      }
      this.activeClass = index;
    },
    showMenu() {
      this.showNav = !this.showNav;
    },
  },
};
</script>
<style lang="scss">

.nav {
  position: relative;

  width: 100%;
  // height: 178px;
  background: rgba(255, 255, 255, 0.46);
  backdrop-filter: blur(4px);
  z-index: 999;
  padding: 30px 63px 0 0;

  .main {
    // width: 80%;
    height: 100%;
    display: flex;
    margin-left: 72px;
    // justify-content: space-between;
    align-items: center;
    img {
      width: 201px;
      height: 68px;
    }
    .column {
      // width: 80%;
      height: 100%;
      display: flex;
      margin-left: 109px;
      line-height: 29px;
      padding-top: 36px;
      padding-bottom: 21px;
      // justify-content: flex-end;
      // align-items: center;

      .link {
        transition: all 0.5s;
        margin-right: 100px;
        display: block;
        font-size: 21px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        cursor: pointer;
        &.active {
          position: relative;

          &::after {
            position: absolute;
            bottom: -16px;
            left: 50%;
            margin-left: -15.5px;
            content: "";
            width: 31px;
            height: 5px;
            background: #000000;
          }
        }
        &:last-child {
          margin-right: 0;
        }
        .routerlink {
          font-size: 21px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
        }
      }
    }

    .search {
      width: 287px;
      height: 48px;
      background: #ffffff;
      border-radius: 24px;
      border: 1px solid rgba(0, 0, 0, 0.32);
      margin-left: 68px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .search-icon {
        margin-left: 15px;
        display: flex;
        align-items: center;
        img {
          width: 24px;
          height: 24px;
        }
      }

      .search-input {
        input {
          border: none;
          height: 35px;
          // width: 200px;
          text-decoration: none;
          // margin-left: 15px;
          outline: none;
        }
      }

      .search-button {
        width: 67px;
        height: 41px;
        background: #000000;
        border-radius: 24px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
        margin-right: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.lx-toast {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 60px!important;
  border-radius: 20px;
  font-size: 40px!important;
  font-family: PingFangSC-Medium, PingFang SC;
}
@media screen and (max-width: 800px) {

  .lx-toast {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 120px!important;
    border-radius: 20px;
    font-size: 80px!important;
    font-family: PingFangSC-Medium, PingFang SC;
  }
  .nav {
    position: fixed;
    padding: 0;
    width: 100%;
    background: rgba(255, 255, 255, 1);
    backdrop-filter: blur(0.020833rem);
    z-index: 999;
    width: 100%;
    // height: 80PX;
    // padding: 30px;
    // background: #abde41;
    .main-mobile {
      // position: absolute;
      width: 100%;
      height: 80PX;
      display: flex;
      justify-content: space-between;
      .main-mobile-logo {
        margin-left: 10PX;
        // position: absolute;
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
          width: 201 * 3px;
          height: 68 * 3px;
        }
      }
      .main-mobile-nav {
       
        margin-right: 20PX;
        display: flex;
        align-items: center;
        // position: absolute;
        right: 20PX;
        img{
          width: 30PX;
          height: 20PX;
        }
        // z-index: 99999;
      }
    }
    .main-mobile-column {
      position: relative;
      // top: 80PX;
      width: 100%;
      background: #ffffff;
      padding-right: 10px;
      box-sizing: border-box;
      // display: flex;
      // align-items: center;
      padding-bottom: 21px;
      height: calc(100vh - 80PX);
      padding: 0 20PX;

      &__search{
        width: 100%;
        height: 50PX;
        // padding: 20PX;
        box-sizing: border-box;
        display: flex;
        border-bottom: 1px solid #ccc;
        input{
          width: 100%;
           height: 40PX;
          border: none;
           border: 1px solid #ccc;
           border-radius: 25PX;
           outline: none;
           padding-left: 10PX;
        }
      }
      &__search-icon{
        position: absolute;
        width: 20PX;
        height: 20PX;
        display: flex;
        right: 0;
        top: 12PX;
        right: 30PX;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .link {
        display: flex;
        align-items: center;
        position: relative;
        font-size: 16PX;
        height: 50PX;
        border-bottom: 1px solid #ccc;
        // text-align: center;
        justify-content: center;
        .routerlink {
          font-size: 25PX;
          font-weight: bold;
          color: #fff;
        }
      }
    }
  }
  /*  .nav{
        position: fixed;
        top: 0;
    //   width: 100%;
    //   height: 5.333rem;
    //   box-sizing: border-box;
    //   //display: flex;
    //   //justify-content: flex-start;
      .main{
        width: 100vw;
        height: 150px;
        background: #ABDE41;
        // display: flex;
        // //justify-content: space-between;
        // align-items: center;
        img{
        width:20%;
        margin-left: 10px
      }
      .column{
          width:75% ;
          height:100%;
          display: flex;
          justify-content: flex-end;
          align-items:center;
          ul{width: 100%;
              font-size: 6px;
              color: #fff;
             font-weight:bold;
            //  display: flex;
            //  flex-wrap: wrap;
            //  justify-content: space-between;

              li{
                float: left;

              }
          }
       }
      }
   }*/
}
</style>
